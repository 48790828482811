import styled from 'styled-components';
import { theme } from 'styled-tools';

const TextInputLabel = styled.div`
    flex: 1;
    margin-left: ${theme('dims.subscribeForm.textInputLabel.marginLeft')};
    color: ${theme('colors.subscribeForm.textInputLabel.color')};
    font-size: ${theme('fonts.subscribeForm.inputLabel.size')};
`;

export default TextInputLabel;
