import styled from 'styled-components';
import { theme } from 'styled-tools';

const Modal = styled.div`
    background: red;
    position: relative;
    display: flex;
    
    font-family: ${theme('fonts.subscribeForm.family')};
    
    width: ${theme('dims.subscribeForm.modal.width')};
    max-width: calc(100% - 40px);
    
    overflow: hidden;
    margin-top: ${theme('dims.subscribeForm.modal.marginVertical')};
    margin-bottom: ${theme('dims.subscribeForm.modal.marginVertical')};
    box-shadow: ${theme('shadows.materialShadow')};
    
    @media (max-width: ${theme('dims.subscribeForm.mobileMediaQueryThreshold')}) {
        height: ${theme('dims.subscribeForm.modal.mobile.height')};
    }
    
    @media (min-width: ${theme('dims.subscribeForm.mobileMediaQueryThreshold')}) {
        height: ${theme('dims.subscribeForm.modal.desktop.height')};
    }
    
    max-height: 90vh;
    border-radius: 1rem;
    background: ${theme('colors.subscribeForm.modalBackground')};
    
    z-index: ${theme('dims.subscribeForm.modal.zIndex')};
`;

export default Modal;
