import styled from 'styled-components';
import { theme } from 'styled-tools';

const PopupHeader = styled.div`
    width: 100%;
    padding-left: ${theme('dims.mapPopup.header.paddingHorizontal')};
    padding-right: ${theme('dims.mapPopup.header.paddingHorizontal')};
    font-size: ${theme('fonts.mapPopup.header.size')};
    color: ${theme('colors.brandBlue')};
`;

export default PopupHeader;
