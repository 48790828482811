import styled from 'styled-components';
import { theme } from 'styled-tools';

export const MapOverlayContentWrapper = styled.div`
      height: ${theme('dims.mapComponents.mapOverlayContentWrapperHeight')};
      width: 100%;
      background-color: ${theme('colors.mapComponents.mapOverlayContentBackgroundColor')};      
      font-size: ${theme('fonts.mapComponents.mapOverlayContentWrapperFontSize')};
      color: ${theme('colors.mapComponents.mapOverlayContentColor')};
      opacity: 0.6;
      display: flex;
      justify-content: center;
      align-items: center;
`;
