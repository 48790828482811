import styled from 'styled-components';
import { theme } from 'styled-tools';

const HeaderItem = styled.div`
    display: flex;
    flex: 1;
    height: 100%;
    
    align-items: center;
    justify-content: center;
    
    font-size: ${theme('fonts.searchResults.header.size')};
    font-weight: ${theme('fonts.searchResults.header.fontWeight')};
    
    color: white;
`;

export default HeaderItem;
