import styled from 'styled-components';
import { theme } from 'styled-tools';

const FormContent = styled.div`
    display: flex;
    
    flex-direction: column;
    align-items: center;
    text-align: left;
    flex: 1;
    
    width: 100%;
    height: calc(100% - ${theme('dims.subscribeForm.formContent.paddingVertical')});
    max-height: calc(100% - ${theme('dims.subscribeForm.formContent.paddingVertical')});
    
    padding-bottom: ${theme('dims.subscribeForm.formContent.paddingVertical')};
    
    padding-left: ${theme('dims.subscribeForm.formContent.paddingHorizontal')};
    padding-right: ${theme('dims.subscribeForm.formContent.paddingHorizontal')};
    
    overflow-y: scroll;
    
    ::-webkit-scrollbar {
         display: none;
    }
`;

export default FormContent;
