import React, { useEffect, useRef } from 'react';
import { FullscreenControl, GeolocateControl } from 'react-map-gl';
import { identity } from 'ramda';

import PropTypes from 'prop-types';
import { ControlsWrapper } from './ControlsWrapper';
import { GeolocateControlWrapper } from './GeolocateControlWrapper';
import { FullscreenControlWrapper } from './FullscreenControlWrapper';
import { GEOLOCATION_TIMEOUT } from '../const';

export const Controls = ({
    onUserLocationChange, onClick, changeGeolocationState, setGeolocationPosition, blockGeolocationOnFail
}) => {
    const geolocationControlRef = useRef();
    useEffect(() => navigator.geolocation.getCurrentPosition(
        (userLocation) => {
            setGeolocationPosition(userLocation.coords);
        },
        () => {
            blockGeolocationOnFail();
        }
    ),
    []);

    const onWrapperClick = () => navigator.geolocation.getCurrentPosition(
        (userLocation) => {
            changeGeolocationState(userLocation.coords);
        },
        () => {},
        {
            timeout: GEOLOCATION_TIMEOUT
        }
    );

    return (
        <ControlsWrapper>
            <GeolocateControlWrapper
                onClick={onWrapperClick}
            >
                <GeolocateControl
                    positionOptions={{ enableHighAccuracy: true }}
                    ref={geolocationControlRef}
                    onViewportChange={onUserLocationChange}
                    trackUserLocation
                    auto
                />
            </GeolocateControlWrapper>
            <FullscreenControlWrapper
                onClick={onClick}
            >
                <FullscreenControl />
            </FullscreenControlWrapper>
        </ControlsWrapper>
    );
};

Controls.defaultProps = {
    onUserLocationChange: identity,
    onClick: identity,
    changeGeolocationState: identity,
    setGeolocationPosition: identity,
    blockGeolocationOnFail: identity
};

Controls.propTypes = {
    onUserLocationChange: PropTypes.func,
    onClick: PropTypes.func,
    changeGeolocationState: PropTypes.func,
    setGeolocationPosition: PropTypes.func,
    blockGeolocationOnFail: PropTypes.func
};
