import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'react-map-gl';
import { fromJS, Map } from 'immutable';
import { identity, isNil } from 'ramda';

import CouponButton from '../CouponButton';
import CouponLink from '../CouponLink';
import CloseIcon from '../CloseIcon';
import { PopupContainer, PopupHeader, PopupSpan } from './components';
import { websiteConfig } from '../../websiteConfigs';
import CallLink from '../CallableLink';

const PlaceMarkerInfoPopup = ({ popupInfo, onClose, onLinkClick }) => {
    const secondaryName = popupInfo.get('secondaryName');
    const phone = popupInfo.get('phone');
    return (
        <Popup
            latitude={popupInfo.get('latitude')}
            longitude={popupInfo.get('longitude')}
            closeButton={false}
            style={{
                padding: '20px 20px 20px'
            }}
        >
            <CloseIcon size="15px" onClick={onClose} />
            <PopupContainer>
                <PopupHeader>{popupInfo.get('name')}</PopupHeader>
                {secondaryName
                    ? <h4>{secondaryName}</h4>
                    : null}
                <PopupSpan>{popupInfo.get('address')}</PopupSpan>
                <PopupSpan>{`${popupInfo.get('postalCode')} ${popupInfo.get('city')}`}</PopupSpan>
                {phone
                    ? (
                        <PopupSpan>
                            <CallLink href={`tel:${phone}`}>
                                {`tel. ${phone}`}
                            </CallLink>
                        </PopupSpan>
                    )
                    : null}
                {
                    isNil(websiteConfig.popoverLinkUrl)
                        ? (<CouponButton onClick={() => onLinkClick(popupInfo.get('id'))}> bezpłatne soczewki</CouponButton>)
                        : (<CouponLink href={websiteConfig.popoverLinkUrl} target="_blank">bezpłatne soczewki</CouponLink>)
                }

            </PopupContainer>
        </Popup>
    );
};

export default memo(PlaceMarkerInfoPopup);

PlaceMarkerInfoPopup.defaultProps = {
    popupInfo: fromJS({
        id: 0,
        name: '',
        address: '',
        phone: '',
        city: '',
        postalCode: '',
        secondaryName: '',
        latitude: 0,
        longitude: 0
    }),
    onClose: identity,
    onLinkClick: identity
};

PlaceMarkerInfoPopup.propTypes = {
    popupInfo: PropTypes.instanceOf(Map),
    onClose: PropTypes.func,
    onLinkClick: PropTypes.func
};
