import {
    FETCH_PLACES, FETCH_PLACES_BY_NAME, FETCHING_ERROR, NO_RESULTS, REBOUND_MAP, SET_MAP_BOUNDS, SET_PLACES
} from './const';

export const setPlaces = (placesList) => ({
    type: SET_PLACES,
    placesList
});

export const fetchPlaces = ({ latitude, longitude, isGeoSearch }) => ({
    type: FETCH_PLACES,
    latitude,
    longitude,
    isGeoSearch
});

export const fetchPlacesByName = (name) => ({
    type: FETCH_PLACES_BY_NAME,
    name
});

export const fetchingError = ({ message, code }) => ({
    type: FETCHING_ERROR,
    message,
    code
});

export const noSearchResults = () => ({
    type: NO_RESULTS
});

export const reboundMap = (points) => ({
    type: REBOUND_MAP,
    points
});

export const setMapBounds = (bounds) => ({
    type: SET_MAP_BOUNDS,
    bounds
});
