import
{
    switchMap, catchError, mergeMap, map, expand, filter
} from 'rxjs/operators';
import { ofType, combineEpics } from 'redux-observable';
import { empty, from, of } from 'rxjs';
import { isEmpty, isNil } from 'ramda';
import { fromJS } from 'immutable';

import {
    fetchingError, fetchPlaces, noSearchResults, reboundMap, setMapBounds, setPlaces
} from './actions';
import { findLocation, searchPlaces } from './api';
import {
    calculateBounds,
    calculateFurthestPoints,
    calculateNewMaxDistance, exportPlacesFromResponse,
    handleFetchingError
} from './utilities';
import { setMapSettings } from '../containers/Mapbox/actions';
import {
    CITY_DISTANCE, FETCH_PLACES, FETCH_PLACES_BY_NAME, REBOUND_MAP
} from './const';

const fetchPlacesEpic = (action$) => action$.pipe(
    ofType(FETCH_PLACES),
    switchMap(({ latitude, longitude, isGeoSearch }) => from([[]]).pipe(
        expand((data, i) => {
            const newMaxDistance = calculateNewMaxDistance(i);
            return isEmpty(data) ? searchPlaces(latitude, longitude, newMaxDistance) : empty();
        }),
        filter((data) => !isEmpty(data)),
        mergeMap((response) => {
            const actionsToEmit = [];
            const places = exportPlacesFromResponse(response, latitude, longitude);
            const furthestPoints = calculateFurthestPoints(places);
            const shouldReboundMap = furthestPoints.distance > CITY_DISTANCE
                || (!isNil(isGeoSearch) && isGeoSearch);
            if (shouldReboundMap) {
                const newBounds = calculateBounds(furthestPoints.points, latitude, longitude);
                actionsToEmit.push(reboundMap(newBounds));
            }
            actionsToEmit.push(setPlaces(fromJS(places)));

            return actionsToEmit;
        }),
        catchError((error) => of(fetchingError(error)))
    ))
);

const setNewBoundsEpic = (action$) => action$.pipe(
    ofType(REBOUND_MAP),
    switchMap(({ points }) => from([points]).pipe(
        map((points) => setMapBounds(fromJS(points)))
    ))
);

const findLocationByNameEpic = (action$) => action$.pipe(
    ofType(FETCH_PLACES_BY_NAME),
    switchMap(({ name }) => from(findLocation(name)).pipe(
        mergeMap((position) => isNil(position) ? of(noSearchResults()) : [fetchPlaces(position), setMapSettings(position)]),
        catchError((error) => of(handleFetchingError(error)))
    ))
);

const mapEpics = combineEpics(
    fetchPlacesEpic,
    findLocationByNameEpic,
    setNewBoundsEpic
);

export default mapEpics;
