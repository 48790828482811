/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */

import React, { useEffect, useRef, useState } from 'react';
import ReactMapGL, { FlyToInterpolator, WebMercatorViewport } from 'react-map-gl';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import useMeasure from 'react-use-measure';
import { ResizeObserver } from '@juggle/resize-observer';
import { isMobile } from 'react-device-detect';
import { fromJS } from 'immutable';
import {
    identity, ifElse, isNil, equals
} from 'ramda';
import 'mapbox-gl/dist/mapbox-gl.css';

import {
    mapBoundsChanged, closePopup, moveMap, setMapSettings, displayPopupInfo, setGeolocationState, blockGeolocation
} from './actions';
import {
    getMapSettings,
    shouldPopupBeDisplayedSelector,
    getBoundsState,
    getBounds,
    getGeolocationState,
    getIsGeolocationBlocked
} from './selectors';
import {
    BOUNDS_STATE, controlSettings, INITIAL_POPUP_INFO, INITIAL_VIEWPORT, mapStyle
} from './const';
import { AutoResizeMapWrapper } from './components/AutoResizeMapWrapper';
import { getListOfPlaces } from '../../redux/selectors';
import { fetchPlaces } from '../../redux/actions';
import PlaceMarkerInfoPopup from '../../components/PlaceMarkerInfoPopup';
import {
    boundsFromPoints,
    calculateNewCoordinates,
    fromBoundsToBox,
    isPointCloseToBoundary,
    takeCoordinatesFromImmutable
} from './utilities';
import { showSubscribeFormModalAction } from '../SubscribeFormModal/actions';
import { Controls } from './components/Controls';
import { MapOverlay } from './components/MapOverlay';
import MyMapController from './MyMapControllerClass';
import PlaceMarkers from '../../components/PlaceMarkers';

const Mapbox = () => {
    const dispatch = useDispatch();
    const reduxViewport = useSelector(getMapSettings, shallowEqual);
    const listOfPlaces = useSelector(getListOfPlaces, shallowEqual);
    const shouldPopupBeDisplayed = useSelector(shouldPopupBeDisplayedSelector, shallowEqual);
    const newMapBoundsState = useSelector(getBoundsState, shallowEqual);
    const newBounds = useSelector(getBounds, shallowEqual);
    const isGeolocationEnabled = useSelector(getGeolocationState, shallowEqual);
    const isGeolocationBlocked = useSelector(getIsGeolocationBlocked, shallowEqual);

    const mapRef = useRef();

    const [mapWrapperRef, mapWrapperMeasure] = useMeasure({ polyfill: ResizeObserver });
    const [viewport, setViewport] = useState(reduxViewport.toJS());
    const [isOverlayVisible, changeOverlayVisibility] = useState(true);
    const [popupInfo, setPopupInfo] = useState(fromJS(INITIAL_POPUP_INFO));
    const [isFullscreen, setFullscreen] = useState(false);

    const changeFullscreen = () => setFullscreen(!isFullscreen);

    const { width, height } = mapWrapperMeasure;

    const onHover = setPopupInfo;

    useEffect(() => {
        if (!isNil(popupInfo.get('id'))) {
            const bounds = mapRef.current.getMap().getBounds();
            const popoutCoordinates = takeCoordinatesFromImmutable(popupInfo);
            const box = fromBoundsToBox(bounds);
            const shouldBeMoved = isPointCloseToBoundary(box, popoutCoordinates);
            dispatch(displayPopupInfo());

            if (shouldBeMoved) {
                const { latitude, longitude } = calculateNewCoordinates(viewport, popoutCoordinates);

                setViewport({
                    ...viewport,
                    latitude,
                    longitude,
                    transitionDuration: 500,
                    transitionInterpolator: new FlyToInterpolator()
                });
            }
        }
    }, [popupInfo]);

    const onViewportChange = (viewport) => {
        setViewport(viewport);
        dispatch(moveMap(viewport));
    };

    const onPopupClose = () => dispatch(closePopup());

    const popupLensesLinkRedirection = (id) => dispatch(showSubscribeFormModalAction(id));

    const mapSettings = {
        mapboxApiAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
        ...viewport
    };

    const onUserLocationChange = ({ latitude, longitude }) => {
        const viewport = {
            latitude,
            longitude
        };

        dispatch(setMapSettings(viewport));
    };

    useEffect(() => {
        setViewport({
            ...viewport,
            latitude: reduxViewport.get('latitude'),
            longitude: reduxViewport.get('longitude'),
            zoom: reduxViewport.get('zoom')
        });
    }, [reduxViewport]);

    useEffect(() => {
        if (newMapBoundsState === BOUNDS_STATE.CHANGE) {
            const temporaryViewport = new WebMercatorViewport({ width, height })
                .fitBounds(newBounds.toJS());

            const newViewport = {
                ...viewport,
                zoom: temporaryViewport.zoom
            };

            setViewport(newViewport);
            dispatch(setMapSettings(newViewport));
            dispatch(mapBoundsChanged());
        }
    }, [newMapBoundsState, newBounds]);

    const mapController = new MyMapController(changeOverlayVisibility);

    const setGeolocationBounds = (userPosition) => {
        if (!isNil(userPosition)) {
            const points = listOfPlaces.map((placeInfo) => [
                placeInfo.get('latitude'),
                placeInfo.get('longitude')
            ]).push(
                [
                    userPosition.latitude,
                    userPosition.longitude
                ]
            ).toJS();
            const bounds = boundsFromPoints(points);
            const temporaryViewport = new WebMercatorViewport({ width, height }).fitBounds(bounds);
            const newViewport = {
                ...viewport,
                zoom: temporaryViewport.zoom
            };
            setViewport(newViewport);
            dispatch(setMapSettings(newViewport));
        }
    };

    const allowGeolocationDispatching = (action) => ifElse(
        equals(false),
        () => dispatch(action),
        () => identity
    )(isGeolocationBlocked);

    const setGeolocationPosition = (userPosition) => allowGeolocationDispatching(fetchPlaces({
        isGeoSearch: true,
        longitude: userPosition.longitude,
        latitude: userPosition.latitude
    }));

    const changeGeolocationState = (userPosition) => {
        allowGeolocationDispatching(setGeolocationState(!isGeolocationEnabled));
        if (isGeolocationEnabled) {
            setGeolocationBounds(userPosition);
        }
    };
    const blockGeolocationOnFail = () => allowGeolocationDispatching(blockGeolocation());

    return (
        <AutoResizeMapWrapper ref={mapWrapperRef}>
            <ReactMapGL
                {...mapSettings}
                {...controlSettings}
                ref={mapRef}
                width={width || '100%'}
                height={height || '100%'}
                onViewportChange={(viewport) => onViewportChange(viewport)}
                mapStyle={mapStyle}
                controller={mapController}
                touchAction="pan-y"
            >
                {isMobile && !isFullscreen
                    ? (
                        <MapOverlay
                            visibility={isOverlayVisible}
                        />
                    )
                    : null }
                <Controls
                    onUserLocationChange={onUserLocationChange}
                    onClick={changeFullscreen}
                    changeGeolocationState={changeGeolocationState}
                    setGeolocationPosition={setGeolocationPosition}
                    blockGeolocationOnFail={blockGeolocationOnFail}
                />
                <PlaceMarkers
                    listOfPlaces={listOfPlaces}
                    onHover={onHover}
                />
                {shouldPopupBeDisplayed
                    ? (
                        <PlaceMarkerInfoPopup
                            popupInfo={popupInfo}
                            onClose={onPopupClose}
                            onLinkClick={popupLensesLinkRedirection}
                        />
                    ) : null}
            </ReactMapGL>
        </AutoResizeMapWrapper>
    );
};

Mapbox.defaultProps = {
    viewport: INITIAL_VIEWPORT
};

Mapbox.propTypes = {
    viewport: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        longitude: PropTypes.number,
        latitude: PropTypes.number,
        zoom: PropTypes.number
    })
};

export default Mapbox;
