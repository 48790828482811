import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { InputWrapper } from './components/styledComponents/InputWrapper';
import { SearchButton } from './components/SearchButton';
import { SearchInput } from './components/SearchInput';
import { fetchPlacesByName } from '../../redux/actions';

export const SearchBar = () => {
    const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState('');
    const startSearchFunction = () => dispatch(fetchPlacesByName(searchQuery));
    return (
        <InputWrapper>
            <SearchInput
                setQuery={setSearchQuery}
                startSearchFunction={startSearchFunction}
            />
            <SearchButton onClick={startSearchFunction} />
        </InputWrapper>
    );
};
