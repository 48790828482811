import styled from 'styled-components';
import { theme } from 'styled-tools';

export const PageHeader = styled.div`
    background-color: ${theme('colors.brandBlue')};
    display: flex;
    justify-content: center;
    width: 100%;
    max-height: ${theme('dims.layout.pageHeaderHeight')};
    padding: ${theme('dims.layout.pageHeaderPadding')};
`;
