export const fonts = {
    basic: {
        size: '10px',
        fontFamily: 'sans-serif'
    },
    searchBar: {
        searchBarInput: '22px',
        searchBarSmallScreenInput: '16px'
    },
    searchResults: {
        header: {
            size: '20px',
            fontWeight: 'bold'
        },
        entry: {
            size: '16px'
        }
    },
    mapPopup: {
        header: {
            size: '22px'
        },
        span: {
            size: '14px'
        }
    },
    mapComponents: {
        mapOverlayContentWrapperFontSize: '30px'
    },
    subscribeForm: {
        family: 'inherit',
        modalTitle: {
            size: '27px'
        },
        inputLabel: {
            size: '12px'
        },
        subscribeButton: {
            size: '16px'
        },
        legalInfo: {
            size: '11px'
        },
        checkboxLabel: {
            size: '12px'
        }
    }
};
