import styled from 'styled-components';
import { theme } from 'styled-tools';

const AllConsentsWrapper = styled.div`
    flex: 1;
    display: flex;
    
    @media only screen and (max-width: ${theme('dims.searchResults.mediaQueries.mobile')}) {
        flex-direction: column;
    }
`;

export default AllConsentsWrapper;
