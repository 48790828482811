import styled from 'styled-components';
import { theme } from 'styled-tools';

export const PinImage = styled.img`
    position: absolute;
    transform: translate3d(${theme('dims.mapComponents.pinImageTransformation')});
    max-height: ${theme('dims.mapComponents.pin.maxHeight')};
    max-width: ${theme('dims.mapComponents.pin.maxWidth')};
    aspect-ratio: ${theme('dims.mapComponents.pin.aspectRatio')};
`;
