import {
    SHOW_SUBSCRIBE_FORM_MODAL,
    HIDE_SUBSCRIBE_FORM_MODAL,

    SUBSCRIBE_USER_REQUEST, SUBSCRIBE_SUCCESS, SUBSCRIBE_ERROR_OCCURRED
} from './constants';

export const subscribeUserRequestAction = (name, email, phone, consents, placeId) => ({
    type: SUBSCRIBE_USER_REQUEST,
    name,
    email,
    phone,
    consents,
    placeId
});

export const showSubscribeFormModalAction = (placeId) => ({
    type: SHOW_SUBSCRIBE_FORM_MODAL,
    placeId
});

export const hideSubscribeFormModalAction = () => ({
    type: HIDE_SUBSCRIBE_FORM_MODAL
});

export const subscribeSuccessAction = () => ({
    type: SUBSCRIBE_SUCCESS
});

export const subscribeErrorOccurred = (error) => ({
    type: SUBSCRIBE_ERROR_OCCURRED,
    error
});
