import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { identity } from 'ramda';
import { fromJS, List } from 'immutable';

import PlaceMarker from './PlaceMarker';

const PlaceMarkers = ({
    listOfPlaces, onHover
}) => listOfPlaces.map((placeInfo) => (
    <PlaceMarker
        key={`key-${placeInfo.get('id')}`}
        placeInfo={placeInfo}
        onHover={onHover}
    />
));

PlaceMarkers.defaultProps = {
    listOfPlaces: fromJS([]),
    onHover: identity
};

PlaceMarkers.propTypes = {
    listOfPlaces: PropTypes.instanceOf(List),
    onHover: PropTypes.func
};

export default memo(PlaceMarkers);
