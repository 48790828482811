import styled from 'styled-components';
import { theme } from 'styled-tools';
import { SUBSCRIBE_FORM_STATUS } from '../constants';

const SubscribeButton = styled.button`
    width: 100%;
    height: ${theme('dims.subscribeForm.subscribeButton.height')};
    color: ${theme('colors.subscribeForm.subscribeButton.color')};
    font-weight: bold;
    font-size: ${theme('fonts.subscribeForm.subscribeButton.size')};
    background: ${(props) => {
        switch (props.subscribeFormStatus) {
            case SUBSCRIBE_FORM_STATUS.ERROR_OCCURRED: {
                return theme('colors.error');
            }
            case SUBSCRIBE_FORM_STATUS.SUCCESS: {
                return theme('colors.success');
            }
            default:
                return theme('colors.subscribeForm.subscribeButton.backgroundColor');
        }
    }};
    
    border: none;
    border-radius: ${theme('dims.subscribeForm.subscribeButton.borderRadius')};
    margin-top: ${theme('dims.subscribeForm.subscribeButton.marginVertical')};
    margin-bottom: ${theme('dims.subscribeForm.subscribeButton.marginVertical')};
    
    :focus {
        outline: none;
    }

    cursor: pointer;
`;

export default SubscribeButton;
