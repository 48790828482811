import React from 'react';

import { Layout } from '../../components/Layout';
import { StyledApp } from './components/StyledApp';
import SubscribeForm from '../SubscribeFormModal';

const App = () => (
    <StyledApp>
        <SubscribeForm />
        <Layout />
    </StyledApp>
);

export default App;
