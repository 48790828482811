import styled from 'styled-components';
import { theme } from 'styled-tools';

const Row = styled.div`
    display: flex;
    padding-top: ${theme('dims.searchResults.row.paddingVertical')};
    padding-bottom: ${theme('dims.searchResults.row.paddingVertical')};
    background-color: ${(props) => props.headerRow ? theme('colors.brandBlue') : 'white'};
    border-bottom: 1px solid ${theme('colors.searchResults.row.border')};
    
    &:hover {
        background-color: ${(props) => props.headerRow ? theme('colors.brandBlue') : theme('colors.searchResults.row.hover')};
    }
    
    @media only screen and (min-width: ${theme('dims.searchResults.mediaQueries.mobile')}) {
        width: 100%;
        min-height: ${theme('dims.searchResults.row.minHeight')};
        flex-shrink: 0;

        align-items: center;
        justify-content: center;
    }
    
    @media only screen and (max-width: ${theme('dims.searchResults.mediaQueries.mobile')}) {
        display: ${(props) => props.headerRow ? 'none' : 'block'};
    }
`;

export default Row;
