import React from 'react';

import { PageBox } from './PageBox';
import { PageHeader } from './PageHeader';
import { PageContent } from './PageContent';
import Mapbox from '../containers/Mapbox';
import { SearchBar } from '../containers/SearchBar';
import ResultsTable from '../containers/ResultsTable';

export const Layout = () => (
    <PageBox>
        <PageHeader>
            <SearchBar />
        </PageHeader>
        <PageContent>
            <Mapbox />
            <ResultsTable />
        </PageContent>
    </PageBox>

);
