export const SHOW_SUBSCRIBE_FORM_MODAL = 'SUBSCRIBE_FORM/SHOW_SUBSCRIBE_FORM_MODAL';
export const HIDE_SUBSCRIBE_FORM_MODAL = 'SUBSCRIBE_FORM/HIDE_SUBSCRIBE_MODAL';

export const SUBSCRIBE_USER_REQUEST = 'SUBSCRIBE_FORM/SUBSCRIBE_USER_REQUEST';

export const SUBSCRIBE_ERROR_OCCURRED = 'SUBSCRIBE_FORM/SUBSCRIBE_ERROR_OCCURED';
export const SUBSCRIBE_SUCCESS = 'SUBSCRIBE_FORM/SUBSCRIBE_SUCCESS';

export const SUBSCRIBE_FORM_STATUS = {
    FILLING_INFORMATION: 'FILLING_INFORMATION',
    AWAITING_RESULT_FROM_SERVER: 'AWAITING_RESULT_FROM_SERVER',
    ERROR_OCCURRED: 'ERROR_OCCURRED',
    SUCCESS: 'SUCCESS'
};

export const FIELD_VALIDATION_STATUSES = {
    OK: 'OK',
    ERROR: 'ERROR'
};
const fieldValidationOk = {
    status: FIELD_VALIDATION_STATUSES.OK
};

const fieldValidationError = (error) => ({
    status: FIELD_VALIDATION_STATUSES.ERROR,
    error
});

export const SUBSCRIBE_FORM_TEXT_INPUTS = [
    {
        name: 'name',
        label: 'Imię',
        validate: (name) => {
            if (!name) {
                return fieldValidationError('Pole wymagane');
            }

            return fieldValidationOk;
        }
    },
    {
        name: 'email',
        label: 'Adres email',
        validate: (email) => {
            if (!email) {
                return fieldValidationError('Pole wymagane');
            }
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
                return fieldValidationError('Niepoprawny adres email');
            }

            return fieldValidationOk;
        }
    },
    {
        name: 'phone',
        label: 'Numer telefonu',
        validate: (phone) => {
            if (phone === '') {
                return fieldValidationOk;
            }
            if (!/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/.test(phone)) {
                return fieldValidationError('Niepoprawny numer telefonu');
            }

            return fieldValidationOk;
        }
    }
];

export const SUBSCRIBE_FORM_CHECKBOXES = {
    EMAIL: {
        id: 'email',
        name: 'Poczta elektroniczna (e-mail)',
        errorPropName: 'consentEmail'
    },
    SMS: {
        id: 'sms',
        name: 'SMS/MMS (krótka wiadomość tekstowa lub multimedialna w sieciach telefonii komórkowej) ',
        errorPropName: 'consentSms'
    },
    PHONE_VOICE: {
        id: 'phone_voice',
        name: 'Telefoniczne połączenia głosowe '
    },
    AFFILIATE: {
        id: 'affiliate',
        name: 'Zgadzam się na otrzymywanie informacji handlowych za pomocą preferowanych kanałów komunikacji również od innych spółek z Grupy CooperVision.'
    },
    COOKIES: {
        id: 'cookies',
        name: 'Wyrażam zgodę na otrzymywanie spersonalizowanej komunikacji opartej na profilowaniu i korzystaniu z plików cookie podczas korzystania z produktów, usług i stron internetowych firmy CooperVision'
    }
};
