import styled from 'styled-components';
import { theme } from 'styled-tools';

const TermsLink = styled.a`
    font-size: ${theme('fonts.subscribeForm.legalInfo.size')};
    color: ${theme('colors.brandBlue')};
    cursor: pointer;
    margin-top: 6px;
    margin-bottom: 6px;
    text-decoration: none;
`;

export default TermsLink;
