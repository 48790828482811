import React, { memo } from 'react';
import { Marker } from 'react-map-gl';
import PropTypes from 'prop-types';
import { identity, isNil } from 'ramda';
import { fromJS, Map } from 'immutable';

import { theme } from '../themes';
import { PinImage } from './PinImage';

const PlaceMarker = ({
    placeInfo, onHover
}) => (
    <Marker
        longitude={placeInfo.get('longitude')}
        latitude={placeInfo.get('latitude')}
    >
        <PinImage
            onMouseOver={() => onHover(placeInfo)}
            src={isNil(placeInfo.get('custom_pin'))
                ? theme.images.icons.pin
                : placeInfo.get('custom_pin')}
            alt=""
        />
    </Marker>
);

PlaceMarker.defaultProps = {
    placeInfo: fromJS({
        id: 0,
        latitude: 0,
        longitude: 0,
        name: '',
        address: '',
        phone: '',
        city: ''
    }),
    onHover: identity
};

PlaceMarker.propTypes = {
    placeInfo: PropTypes.instanceOf(Map),
    onHover: PropTypes.func
};

export default memo(PlaceMarker);
