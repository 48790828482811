import {
    SET_MAP_SETTINGS, DISPLAY_POPUP_INFO, MOVE_MAP_REQUEST, MAP_BOUNDS_CHANGED, CLOSE_POPUP, SET_GEOLOCATION, BLOCK_GEOLOCATION
} from './const';

export const setMapSettings = (viewport) => ({
    type: SET_MAP_SETTINGS,
    viewport
});

export const displayPopupInfo = () => ({
    type: DISPLAY_POPUP_INFO
});

export const moveMap = ({ latitude, longitude, zoom }) => ({
    type: MOVE_MAP_REQUEST,
    latitude,
    longitude,
    zoom
});

export const mapBoundsChanged = () => ({
    type: MAP_BOUNDS_CHANGED
});

export const closePopup = () => ({
    type: CLOSE_POPUP
});

export const setGeolocationState = (value) => ({
    type: SET_GEOLOCATION,
    value
});

export const blockGeolocation = () => ({
    type: BLOCK_GEOLOCATION
});
