import styled from 'styled-components';
import { theme } from 'styled-tools';

const ErrorMessage = styled.div`
    flex: 1;
    margin-left: ${theme('dims.subscribeForm.textInputLabel.marginLeft')};
    margin-bottom: ${theme('dims.subscribeForm.textInputError.marginBottom')};;
    color: ${theme('colors.error')};
    font-size: ${theme('fonts.subscribeForm.inputLabel.size')};
    min-width: 50px;
    word-break: keep-all;
`;

export default ErrorMessage;
