import { fromJS } from 'immutable';
import {
    SHOW_SUBSCRIBE_FORM_MODAL,
    HIDE_SUBSCRIBE_FORM_MODAL,
    SUBSCRIBE_FORM_STATUS,
    SUBSCRIBE_USER_REQUEST, SUBSCRIBE_ERROR_OCCURRED, SUBSCRIBE_SUCCESS
} from './constants';

const initialState = fromJS({
    placeId: null,
    isSubscribeFormModalVisible: false,
    subscribeFormStatus: SUBSCRIBE_FORM_STATUS.FILLING_INFORMATION,
    subscribeError: null
});

function resetFormState(state) {
    return state.set('subscribeFormStatus', SUBSCRIBE_FORM_STATUS.FILLING_INFORMATION)
        .set('subscribeError', null)
        .set('placeId', null);
}

export const subscribeFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUBSCRIBE_USER_REQUEST:
            return state.set('subscribeFormStatus', SUBSCRIBE_FORM_STATUS.AWAITING_RESULT_FROM_SERVER);
        case SUBSCRIBE_ERROR_OCCURRED:
            return state.set('subscribeFormStatus', SUBSCRIBE_FORM_STATUS.ERROR_OCCURRED)
                .set('subscribeError', action.error);
        case SUBSCRIBE_SUCCESS:
            return state.set('subscribeFormStatus', SUBSCRIBE_FORM_STATUS.SUCCESS);
        case SHOW_SUBSCRIBE_FORM_MODAL:
            return resetFormState(state)
                .set('isSubscribeFormModalVisible', true)
                .set('placeId', action.placeId);
        case HIDE_SUBSCRIBE_FORM_MODAL:
            return resetFormState(state)
                .set('isSubscribeFormModalVisible', false);
        default:
            return state;
    }
};
