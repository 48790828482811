import { theme } from 'styled-tools';

export const colors = {
    brandBlue: '#5283bd',
    error: '#B00020',
    success: '#7aea73',
    searchBar: {
        inputDefaultBackground: 'white',
        inputPlaceholderFontColor: 'grey'
    },
    mapComponents: {
        popupBackgroundColor: 'white',
        popupContentBoxShadowColor: '(0,0,0,0.3)',
        mapOverlayContentBackgroundColor: 'grey',
        mapOverlayContentColor: 'white'
    },
    searchResults: {
        row: {
            hover: '#eee',
            border: '#eee'
        }
    },
    subscribeForm: {
        modalBackground: 'white',
        modalTitle: '#282828',
        backgroundOverlay: 'rgba(0, 0, 0, 0.35)',
        legalInfo: '#252b2c',
        textInput: {
            border: '#eee'
        },
        subscribeButton: {
            color: 'white',
            backgroundColor: '#5393e7'
        },
        textInputLabel: {
            color: '#86a5a8'
        },
        privacyInfo: {
            background: 'white'
        }
    }
};

export const shadows = {
    mapComponents: {
        popupContentBoxShadow: `0 2px 7px 1px ${theme(colors.mapComponents.popupContentBoxShadowColor)}`
    },
    materialShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)'
};
