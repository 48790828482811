import { fromJS } from 'immutable';

import {
    INITIAL_VIEWPORT,
    POPUP_STATES,
    SET_MAP_SETTINGS,
    CLOSE_POPUP,
    BOUNDS_STATE,
    MAP_BOUNDS_CHANGED,
    DISPLAY_POPUP_INFO,
    SET_GEOLOCATION, BLOCK_GEOLOCATION
} from './const';
import { SET_MAP_BOUNDS, SET_PLACES } from '../../redux/const';

const initialState = fromJS({
    viewport: INITIAL_VIEWPORT,
    popupState: POPUP_STATES.HIDDEN,
    boundsSettings: {
        bounds: [],
        boundsState: BOUNDS_STATE.READY
    },
    isGeolocationEnabled: false,
    isGeolocationBlocked: false
});

export const mapboxReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MAP_SETTINGS:
            return state
                .mergeDeep(fromJS({
                    viewport: action.viewport
                }))
                .removeIn(['viewport', 'transitionDuration'])
                .removeIn(['viewport', 'transitionInterpolator']);
        case DISPLAY_POPUP_INFO:
            return state
                .set('popupState', POPUP_STATES.DISPLAYED);
        case CLOSE_POPUP:
            return state
                .set('popupState', POPUP_STATES.HIDDEN);
        case SET_PLACES:
            return state.set('popupState', POPUP_STATES.HIDDEN);
        case SET_MAP_BOUNDS:
            return state
                .setIn(['boundsSettings', 'bounds'], action.bounds)
                .setIn(['boundsSettings', 'boundsState'], BOUNDS_STATE.CHANGE);
        case MAP_BOUNDS_CHANGED:
            return state.setIn(['boundsSettings', 'boundsState'], BOUNDS_STATE.READY);
        case SET_GEOLOCATION:
            return state.set('isGeolocationEnabled', action.value);
        case BLOCK_GEOLOCATION:
            return state
                .set('isGeolocationBlocked', true)
                .set('isGeolocationEnabled', false);
        default:
            return state;
    }
};
