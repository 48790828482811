import styled from 'styled-components';
import { theme } from 'styled-tools';

export const InputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center; 
    background-color: ${theme('colors.searchBar.inputDefaultBackground')};
    width: ${theme('dims.searchBar.inputWrapperWidth')};
    max-width: 100%;
    height: ${theme('dims.searchBar.inputWrapperHeight')};
    border-radius: ${theme('dims.searchBar.inputWrapperBorderRadius')};
    margin-left: ${theme('dims.searchBar.inputWrapperMarginHorizontal')};
    margin-right: ${theme('dims.searchBar.inputWrapperMarginHorizontal')};
`;
