export const SET_PLACES = 'SET_PLACES';
export const FETCH_PLACES = 'FETCH_PLACES';
export const FETCH_PLACES_BY_NAME = 'FETCH_PLACES_BY_NAME';
export const FETCHING_ERROR = 'FETCHING_ERROR';
export const NO_RESULTS = 'NO_RESULTS';
export const REBOUND_MAP = 'REBOUND_MAP';
export const SET_MAP_BOUNDS = 'SET_MAP_BOUNDS';
export const SET_GEOLOCATION_VIEW = 'SET_GEOLOCATION_VIEW';

export const SERVICED_COUNTRY_ISO_CODE = 'pl';

const PLACE_TYPES = {
    COUNTRY: 'country',
    REGION: 'region',
    POSTCODE: 'postcode',
    DISTRICT: 'district',
    PLACE: 'place',
    LOCALITY: 'locality',
    NEIGHBORHOOD: 'neighborhood',
    ADDRESS: 'address',
    POI: 'poi'
};

export const PLACE_TYPES_ZOOM_RATE_MAP = {
    [PLACE_TYPES.COUNTRY]: 5,
    [PLACE_TYPES.REGION]: 8,
    [PLACE_TYPES.POSTCODE]: 10,
    [PLACE_TYPES.DISTRICT]: 11,
    [PLACE_TYPES.PLACE]: 11,
    [PLACE_TYPES.LOCALITY]: 12,
    [PLACE_TYPES.NEIGHBORHOOD]: 13,
    [PLACE_TYPES.ADDRESS]: 15,
    [PLACE_TYPES.POI]: 13
};

export const STATUS_STATES = {
    READY: 'READY',
    FETCHING: 'FETCHING',
    ERROR: 'ERROR'
};

export const CITY_DISTANCE = 15;
export const RETRY_QUERY_ADDITIONAL_RANGE = 15;
