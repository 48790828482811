import styled from 'styled-components';
import { theme } from 'styled-tools';

const CheckboxLabel = styled.label`
    font-size: ${theme('fonts.subscribeForm.checkboxLabel.size')};
    font-weight: 500 !important;
    margin-left: ${theme('dims.subscribeForm.checkboxLabel.marginLeft')};
`;

export default CheckboxLabel;
