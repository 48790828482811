import styled from 'styled-components';
import { theme } from 'styled-tools';

const FormImage = styled.img`
    width: ${theme('dims.subscribeForm.formImage.width')};
    height: 100%;
    object-fit: cover;
    
    @media (max-width: ${theme('dims.subscribeForm.mobileMediaQueryThreshold')}) {
        display: none;
        width: 0 !important;
    }
`;

export default FormImage;
