import {
    reduce, curry, assoc, map, keys, compose, min, max
} from 'ramda';

export const isPointCloseToBoundary = (box, point) => {
    const verticalSize = box.north - box.south;
    const horizontalSize = box.east - box.west;

    const closeNorthBoundary = (((box.north - point.latitude) / verticalSize) * 100) > 90;
    const closeSouthBoundary = ((point.latitude - box.south) / verticalSize) * 100 > 90;

    const closeEastBoundary = ((box.east - point.longitude) / horizontalSize) * 100 > 90;
    const closeWestBoundary = ((point.longitude - box.west) / horizontalSize) * 100 > 90;

    return closeNorthBoundary || closeEastBoundary || closeSouthBoundary || closeWestBoundary;
};

export const calculateNewCoordinates = (centerPoint, cornerPoint) => {
    const latitude = (centerPoint.latitude * 4 + cornerPoint.latitude) / 5;
    const longitude = (centerPoint.longitude * 4 + cornerPoint.longitude) / 5;
    return { latitude, longitude };
};

const renameKeys = curry(
    (keysMap, obj) => reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, keys(obj))
);

const renameProperties = {
    geo_long: 'longitude',
    geo_lat: 'latitude',
    postal_code: 'postalCode',
    secondary_name: 'secondaryName'
};

const renameGeoProperties = (listOfObjects) => map(renameKeys(renameProperties), listOfObjects);

const removeEmptyPhoneNumbers = (listOfObjects) => map(
    (placeInfo) => placeInfo.phone !== 'None'
        ? placeInfo
        : {
            ...placeInfo,
            phone: null
        },
    listOfObjects
);

export const adaptDataSet = compose(renameGeoProperties, removeEmptyPhoneNumbers);

export const takeCoordinatesFromImmutable = (immutableMap) => ({
    latitude: immutableMap.get('latitude'),
    longitude: immutableMap.get('longitude')
});

export const fromBoundsToBox = ({ _ne, _sw }) => ({
    north: _ne.lat,
    south: _sw.lat,
    west: _sw.lng,
    east: _ne.lng
});

export const boundsFromPoints = (points) => reduce(calculateBounds, INITIAL_BOUNDS, points);

const INITIAL_BOUNDS = [[180, 180], [-180, -180]];

const calculateBounds = ([[minLong, minLat], [maxLong, maxLat]], [lat, long]) => [
    [
        min(minLong, long),
        min(minLat, lat)
    ],
    [
        max(maxLong, long),
        max(maxLat, lat)
    ]
];
