import styled from 'styled-components';
import { theme } from 'styled-tools';

export const GeolocateControlWrapper = styled.div`
    position: absolute;
    top: ${theme('dims.mapComponents.GeolocateControlWrapperTop')};
    left: ${theme('dims.mapComponents.GeolocateControlWrapperLeft')};
    padding: ${theme('dims.mapComponents.GeolocateControlWrapperPadding')};
    width: 50px;
    height: 50px;
    display: flex;
`;
