import { createSelector } from 'reselect';
import { POPUP_STATES } from './const';

export const mapboxSelector = (state) => state.mapbox;

export const getMapSettings = createSelector(
    mapboxSelector,
    (state) => state.get('viewport')
);

export const getPopupState = createSelector(
    mapboxSelector,
    (state) => state.get('popupState')
);

export const shouldPopupBeDisplayedSelector = createSelector(
    getPopupState,
    (state) => state === POPUP_STATES.DISPLAYED
);

export const getBoundsSettings = createSelector(
    mapboxSelector,
    (state) => state.get('boundsSettings')
);

export const getBounds = createSelector(
    getBoundsSettings,
    (state) => state.get('bounds')
);

export const getBoundsState = createSelector(
    getBoundsSettings,
    (state) => state.get('boundsState')
);

export const getGeolocationState = createSelector(
    mapboxSelector,
    (state) => state.get('isGeolocationEnabled')
);

export const getIsGeolocationBlocked = createSelector(
    mapboxSelector,
    (state) => state.get('isGeolocationBlocked')
);
