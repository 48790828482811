import { createSelector } from 'reselect';

export const subscribeFormSelector = (state) => state.subscribeForm;

export const selectIsFormModalVisible = createSelector(
    subscribeFormSelector,
    (state) => state.get('isSubscribeFormModalVisible')
);

export const selectSubscribeFormStatus = createSelector(
    subscribeFormSelector,
    (state) => state.get('subscribeFormStatus')
);

export const selectPlaceId = createSelector(
    subscribeFormSelector,
    (state) => state.get('placeId')
);
