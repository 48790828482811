import React from 'react';
import PropTypes from 'prop-types';
import { identity } from 'ramda';

import { TextInputWrapper } from './styledComponents/TextInputWrapper';
import { INPUT_PLACEHOLDER, SUBMIT_SEARCHING_BUTTON } from '../const';
import { handleKeyPress } from '../utilities';

export const SearchInput = ({ setQuery, startSearchFunction }) => {
    const onKeyPress = (event) => handleKeyPress(event, startSearchFunction, SUBMIT_SEARCHING_BUTTON);
    return (
        <TextInputWrapper
            onKeyPress={onKeyPress}
            type="text"
            placeholder={INPUT_PLACEHOLDER}
            onChange={(event) => setQuery(event.target.value)}
        />
    );
};

SearchInput.defaultProps = {
    setQuery: identity,
    startSearchFunction: identity
};

SearchInput.propTypes = {
    setQuery: PropTypes.func,
    startSearchFunction: PropTypes.func
};
