import React from 'react';

import PrivacyInfoContainer from './components/PrivacyInfoContainer';
import PrivacyLink from '../../containers/SubscribeFormModal/components/PrivacyLink';
import LegalInfo from '../../containers/SubscribeFormModal/components/LegalInfo';
import TermsLink from '../../containers/SubscribeFormModal/components/TermsLink';

const PrivacyInfo = ({ hide }) => (
    <PrivacyInfoContainer>
        <LegalInfo>
            - Administratorem Państwa danych osobowych jest spółka pod firmą CooperVision sp. z o.o. z siedzibą w Warszawie (kod pocztowy 02-697), ul. Domaniewska 44.
        </LegalInfo>
        <LegalInfo>
            - Informacje handlowe mogą dotyczyć produktów, usług, szkoleń lub innych działań promocyjnych. Informacje handlowe mogą być spersonalizowane w oparciu o  profilowanie i wykorzystanie plików cookie,  co umożliwia dostarczenie informacji najlepiej dopasowanych do Państwa preferencji oraz oczekiwań, biorąc pod uwagę Państwa wcześniejsze korzystanie z produktów, usług i stron internetowych CooperVision.
        </LegalInfo>
        <LegalInfo>
            Przysługują Państwu określone prawa, w tym: (i) prawo do żądania dostępu do danych osobowych dotyczących Państwa, (ii) prawo do sprostowania tych danych, (iii) prawo do usunięcia tych danych, (iv) prawo do ograniczenia ich przetwarzania, (v) prawo do wniesienia sprzeciwu wobec przetwarzania danych osobowych dotyczących Państwaw celach marketingowych, w tym profilowania, (vi) prawo do przenoszenia tych danych – na zasadach określonych w Rozporządzeniu o Ochronie Danych Osobowych z dnia 27 kwietnia 2016 r. (“RODO”).
        </LegalInfo>
        <LegalInfo>
            - Dodatkowe informacje na temat zasad przetwarzania danych osobowych przez CooperVision Poland sp. o. o. jest dostępny w Polityce Prywatności
            {' '}
            <TermsLink href="https://coopervision.pl/polityka-prywatnosci" target="_blank" rel="noopener noreferrer">[link]</TermsLink>
        </LegalInfo>
        <PrivacyLink onClick={() => hide()}>
            Zwiń
        </PrivacyLink>
    </PrivacyInfoContainer>
);

export default PrivacyInfo;
