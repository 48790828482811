import React from 'react';
import PropTypes from 'prop-types';

import { MapOverlayContentWrapper } from './MapOverlayContentWrapper';
import { PHONE_MAP_OVERLAY_TEXT } from '../const';

export const MapOverlay = ({ visibility }) => (
    <div>
        {visibility
            ? (
                <MapOverlayContentWrapper>
                    {PHONE_MAP_OVERLAY_TEXT}
                </MapOverlayContentWrapper>
            )
            : null}
    </div>
);

MapOverlay.defaultProps = {
    visibility: true
};

MapOverlay.propTypes = {
    visibility: PropTypes.bool
};
