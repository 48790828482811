import styled from 'styled-components';
import { defaultTo } from "ramda";

const Row = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    margin-top: ${(props) => defaultTo('inherit', props.marginTop)};
`;

export default Row;
