import styled from 'styled-components';
import { theme } from 'styled-tools';

export const StyledSearchButton = styled.button`
    background-color: ${theme('colors.searchBar.inputDefaultBackground')};
    height: ${theme('dims.searchBar.searchButtonHeight')};
    width: ${theme('dims.searchBar.searchButtonWidth')};
    margin-right: ${theme('dims.searchBar.searchButtonRightMargin')};
    border-radius: 50%;
    border: none;
    user-select: none;
    align-items: flex-start;
    cursor: pointer;
    flex-grow: 0;
    flex-shrink: 0;
    outline: none;
`;
