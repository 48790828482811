import styled from 'styled-components';
import { theme } from 'styled-tools';

const PrivacyLink = styled.div`
    font-size: ${theme('fonts.subscribeForm.legalInfo.size')};
    color: ${theme('colors.brandBlue')};
    cursor: pointer;
`;

export default PrivacyLink;
