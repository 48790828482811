/* Redux's values */
export const SET_MAP_SETTINGS = 'SET_MAP_SETTINGS';
export const DISPLAY_POPUP_INFO = 'DISPLAY_POPUP_INFO';
export const MOVE_MAP_REQUEST = 'MOVE_MAP_REQUEST';
export const CLOSE_POPUP = 'CLOSE_POPUP';
export const MAP_BOUNDS_CHANGED = 'MAP_BOUNDS_CHANGED';
export const SET_GEOLOCATION = 'SET_GEOLOCATION';
export const BLOCK_GEOLOCATION = 'BLOCK_GEOLOCATION';

export const INITIAL_VIEWPORT = {
    latitude: 52,
    longitude: 19,
    zoom: 5
};

export const INITIAL_POPUP_INFO = {
    id: null,
    name: '',
    address: '',
    phone: '',
    city: '',
    postalCode: '',
    secondaryName: '',
    latitude: 52,
    longitude: 19
};

export const POPUP_STATES = {
    HIDDEN: 'HIDDEN',
    DISPLAYED: 'DISPLAYED'
};

export const BOUNDS_STATE = {
    READY: 'READY',
    CHANGE: 'CHANGE'
};

export const GEOLOCATION_STATE = {
    DISABLED: 'DISABLED',
    ENABLED: 'ENABLED'
};

/* Epic's values */

export const MOVE_MAP_REQUEST_DEBOUNCE_TIME = 50;

export const GEOSEARCH_ZOOM_VALUE = 15;

/* Container's values */

export const mapStyle = 'mapbox://styles/mapbox/outdoors-v10';

export const PHONE_MAP_OVERLAY_TEXT = 'Przesuń mapę dwoma palcami';

export const controlSettings = {
    dragRotate: false
};

export const GEOLOCATION_TIMEOUT = 1000;
