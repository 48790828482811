import styled from 'styled-components';
import { theme } from 'styled-tools';

const LegalInfo = styled.div`
    font-size: ${theme('fonts.subscribeForm.legalInfo.size')};
    color: ${theme('colors.subscribeForm.legalInfo')};
    margin-top: ${(props) => props.marginTop ? props.marginTop : theme('dims.subscribeForm.legalInfo.defaultMarginVertical')};
    margin-bottom: ${(props) => props.marginBottom ? props.marginTop : theme('dims.subscribeForm.legalInfo.defaultMarginVertical')};
    width: 100%;
    text-align: left;
`;

export default LegalInfo;
