import styled from 'styled-components';
import { theme } from 'styled-tools';

const BackgroundOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${theme('colors.subscribeForm.backgroundOverlay')};

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: ${theme('dims.subscribeForm.backgroundOverlay.zIndex')};
`;

export default BackgroundOverlay;
