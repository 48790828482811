import React, { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Formik, Field } from 'formik';
import { BeatLoader } from 'react-spinners';
import {
    prop, lensProp, reduce, set, includes, isNil

} from 'ramda';

import { hideSubscribeFormModalAction, subscribeUserRequestAction } from './actions';
import formImg from '../../assets/imgs/form_img.jpg';
import {
    TextInput, FullscreenBackground, Modal, FormImage, FormContent, ModalTitle, ModalSubtitle,
    ErrorMessage, TextInputLabel, SubscribeButton, LegalInfo, PrivacyLink, ExpandedCheckboxesLegalText,
    ExpandCheckboxes, CheckboxLabel, AllConsentsWrapper
} from './components';

import Row from '../../components/Row';
import CloseIcon from '../../components/CloseIcon';

import {
    SUBSCRIBE_FORM_CHECKBOXES,
    SUBSCRIBE_FORM_STATUS,
    SUBSCRIBE_FORM_TEXT_INPUTS,
    FIELD_VALIDATION_STATUSES
} from './constants';
import { selectIsFormModalVisible, selectPlaceId, selectSubscribeFormStatus } from './selectors';
import PrivacyInfo from '../../components/PrivacyInfo';
import { SUBSCRIBE_FORM_AGREEMENT, CONSENT_REQUIRED, MISSING_REQUIRED_CONSENTS } from './messages';
import TermsLink from './components/TermsLink';

function SubscribeForm() {
    const dispatch = useDispatch();
    const [areConsentsExpanded, setConsentsExpanded] = useState(false);
    const [isPrivacyInfoExpanded, setPrivacyInfoExpanded] = useState(false);
    const subscribeFormStatus = useSelector(selectSubscribeFormStatus, shallowEqual());
    const placeId = useSelector(selectPlaceId, shallowEqual());
    const isSubscribeFormVisible = useSelector(selectIsFormModalVisible, shallowEqual());

    if (!isSubscribeFormVisible) {
        return (<></>);
    }

    return (
        <FullscreenBackground>
            <Modal>
                <CloseIcon onClick={() => dispatch(hideSubscribeFormModalAction())} />
                <FormImage src={formImg} />
                <FormContent>
                    <ModalTitle>
                        Odbierz swój KOD
                    </ModalTitle>
                    <ModalSubtitle>
                        Zapisz się na naszą listę i otrzymaj
                        {'\n'}
                        kod na BEZPŁATNĄ PARĘ
                        {'\n'}
                        soczewek
                    </ModalSubtitle>
                    <Formik
                        initialValues={{
                            name: '',
                            email: '',
                            phone: '',
                            consents: []
                        }}
                        onSubmit={({
                            name, email, phone, consents
                        }) => {
                            dispatch(subscribeUserRequestAction(name, email, phone, consents, placeId));
                        }}
                        validate={(values) => {
                            const errors = reduce((errors, { name, validate }) => {
                                const { status, error } = validate(prop(name, values));

                                if (status === FIELD_VALIDATION_STATUSES.ERROR) {
                                    // eslint-disable-next-line no-param-reassign
                                    errors = set(lensProp(name), error, errors);
                                }
                                return errors;
                            }, {}, SUBSCRIBE_FORM_TEXT_INPUTS);

                            const { consents, phone } = values;

                            if (!includes(SUBSCRIBE_FORM_CHECKBOXES.EMAIL.id, consents)) {
                                errors.consentEmail = CONSENT_REQUIRED;
                            }

                            if (!includes(SUBSCRIBE_FORM_CHECKBOXES.SMS.id, consents) && phone !== '') {
                                errors.consentSms = CONSENT_REQUIRED;
                            }

                            return errors;
                        }}
                    >
                        {({
                            values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, isValid
                        }) => (
                            <form onSubmit={handleSubmit}>
                                {SUBSCRIBE_FORM_TEXT_INPUTS.map((formTextInput) => (
                                    <>
                                        <TextInputLabel>{formTextInput.label}</TextInputLabel>
                                        <TextInput
                                            type="text"
                                            name={formTextInput.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={prop(formTextInput.name, values)}
                                        />
                                        {
                                            prop(formTextInput.name, errors) && prop(formTextInput.name, touched)
                                            && <ErrorMessage>{prop(formTextInput.name, errors)}</ErrorMessage>
                                        }
                                    </>
                                ))}
                                <SubscribeButton
                                    type="submit"
                                    subscribeFormStatus={subscribeFormStatus}
                                    disabled={!([SUBSCRIBE_FORM_STATUS.FILLING_INFORMATION, SUBSCRIBE_FORM_STATUS.ERROR_OCCURRED].includes(subscribeFormStatus))}
                                >
                                    {
                                        {
                                            [SUBSCRIBE_FORM_STATUS.FILLING_INFORMATION]: 'Wyślij mój kod',
                                            [SUBSCRIBE_FORM_STATUS.AWAITING_RESULT_FROM_SERVER]: <BeatLoader color="#FFF" size={10} />,
                                            [SUBSCRIBE_FORM_STATUS.ERROR_OCCURRED]: 'Wystąpił błąd, spróbuj jeszcze raz',
                                            [SUBSCRIBE_FORM_STATUS.SUCCESS]: 'Kod wysłany'
                                        }[subscribeFormStatus]
                                    }

                                </SubscribeButton>

                                <LegalInfo>{ SUBSCRIBE_FORM_AGREEMENT }</LegalInfo>

                                <Row>
                                    <input
                                        checked={values.consents.length === Object.values(SUBSCRIBE_FORM_CHECKBOXES).length}
                                        type="checkbox"
                                        onClick={() => {
                                            if (values.consents.length === Object.values(SUBSCRIBE_FORM_CHECKBOXES).length) {
                                                setFieldValue('consents', []);
                                            }
                                            else {
                                                setFieldValue('consents', Object.values(SUBSCRIBE_FORM_CHECKBOXES).map(prop('id')));
                                            }
                                        }}
                                        style={{ marginTop: '2px' }}
                                        id="all"
                                    />
                                    <AllConsentsWrapper>
                                        <CheckboxLabel htmlFor="all">
                                            <div>Akceptuję wszystkie zgody</div>
                                        </CheckboxLabel>
                                        <ExpandCheckboxes onClick={() => setConsentsExpanded(!areConsentsExpanded)}>
                                            {areConsentsExpanded ? '(Zwiń zgody)' : '(Rozwiń zgody)'}
                                        </ExpandCheckboxes>
                                        {
                                            (errors.consentSms || errors.consentEmail) && touched.consents
                                            && (
                                                <ErrorMessage>
                                                    { MISSING_REQUIRED_CONSENTS }
                                                </ErrorMessage>
                                            )
                                        }
                                    </AllConsentsWrapper>
                                </Row>

                                { areConsentsExpanded && (
                                    <ExpandedCheckboxesLegalText>
                                        (Proszę zaznaczyć kanał(y) komunikacji, do którego(ych) ma zastosowanie Państwa zgoda)
                                    </ExpandedCheckboxesLegalText>
                                )}

                                { areConsentsExpanded && Object.values(SUBSCRIBE_FORM_CHECKBOXES).map((checkbox) => (
                                    <Row marginTop="4px">
                                        <Field type="checkbox" name="consents" value={checkbox.id} id={checkbox.id} style={{ marginTop: '2px' }} />
                                        <CheckboxLabel htmlFor={checkbox.id}>{checkbox.name}</CheckboxLabel>
                                        {
                                            !isNil(checkbox.errorPropName) && prop(checkbox.errorPropName, errors) && prop('consents', touched)
                                            && <ErrorMessage>{prop(checkbox.errorPropName, errors)}</ErrorMessage>
                                        }
                                    </Row>
                                ))}
                            </form>
                        )}
                    </Formik>

                    <LegalInfo>
                        Możecie Państwo w dowolnym momencie wycofać wszystkie lub niektóre z udzielonych zgód. Niebędzie to miało wpływu na zgodność z prawem działań opartych na zgodzie przed jej wycofaniem. W celu wycofania zgody należy wysłać wiadomość e-mail na adres
                        {' '}
                        <a href="mailto:PolishCS@coopervision.co.uk">PolishCS@coopervision.co.uk</a>
                        , podając kanały komunikacji (w stosownych przypadkach).
                    </LegalInfo>

                    <PrivacyLink onClick={() => setPrivacyInfoExpanded(!isPrivacyInfoExpanded)}>
                        Dowiedz się, jak przetwarzamy Twoje dane
                        {' '}
                        { isPrivacyInfoExpanded && '(zwiń)' }
                    </PrivacyLink>

                    {
                        isPrivacyInfoExpanded && <PrivacyInfo hide={() => setPrivacyInfoExpanded(false)} />
                    }

                    <TermsLink href="http://mojepierwszesoczewki.pl/wp-content/uploads/2020/09/REGULAMIN-PROMOCJI_BEZPŁATNE-SOCZEWKI.pdf" target="_blank" rel="noopener noreferrer">
                        Regulamin promocji
                    </TermsLink>

                </FormContent>
            </Modal>
        </FullscreenBackground>
    );
}

export default SubscribeForm;
