import axios from 'axios';

import { isEmpty } from 'ramda';
import { PLACE_TYPES_ZOOM_RATE_MAP, SERVICED_COUNTRY_ISO_CODE } from './const';
import { adaptDataSet } from '../containers/Mapbox/utilities';

export const API_RETRY_DELAY_TIME = 250;
export const API_MAX_RETRIES = 3;

const PLACES_API_ENDPOINT = process.env.REACT_APP_BACKEND_API;
const GEOCODING_API = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';
const accessToken = process.env.REACT_APP_ACCESS_TOKEN;

export const searchPlaces = (latitude, longitude, maxDistance) => axios.get(
    `${PLACES_API_ENDPOINT}/places?`,
    {
        params: {
            access_token: accessToken,
            lat: latitude,
            long: longitude,
            max_distance: maxDistance
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
        }
    }
)
    .then((response) => adaptDataSet(response.data.data));

export const findLocation = async (name) => {
    const result = await axios.get(
        `${GEOCODING_API}${name}.json`, {
            params: {
                access_token: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
                country: SERVICED_COUNTRY_ISO_CODE,
                language: SERVICED_COUNTRY_ISO_CODE,
                limit: 1
            }
        }
    );
    const { data } = result;

    if (isEmpty(data.features)) {
        return null;
    }

    return {
        latitude: data.features[0].center[1],
        longitude: data.features[0].center[0],
        zoom: PLACE_TYPES_ZOOM_RATE_MAP[(data.features[0].place_type[0])]
    };
};

export const subscribeUserRequest = async (name, email, phone, consents, placeId) => axios.post(`${PLACES_API_ENDPOINT}/activeCampaign`, {
    access_token: accessToken,
    name,
    email,
    phone,
    consents,
    place_id: placeId,
},
{
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
    }
})
    .then((response) => response.data);
