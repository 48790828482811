/// my-map-controller.js
/*  eslint-disable */
import { MapController } from 'react-map-gl';

export default class MyMapController extends MapController {
    constructor(changeOverlayVisibility) {
        super();
        this.changeOverlayVisibility = changeOverlayVisibility;
        this.allowMapUsing = false;
    }

    _onPinchStart(event) {
        if(!this.allowMapUsing) {
            this.changeOverlayVisibility(false);
            this.allowMapUsing = true;
        }
        return super._onPinchStart(event);
    }

    _onPanEnd(event) {
        this.changeOverlayVisibility(true);
        this.allowMapUsing = false;
        return super._onPanEnd(event);
    }
}
