import styled from 'styled-components';
import { theme } from 'styled-tools';

const ExpandedCheckboxesLegalText = styled.div`
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    font-size: ${theme('fonts.subscribeForm.checkboxLabel.size')};
`;

export default ExpandedCheckboxesLegalText;
