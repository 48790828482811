import { combineReducers } from 'redux';
import { mapboxReducer } from './containers/Mapbox/reducer';
import { mapReducer } from './redux/reducer';
import { subscribeFormReducer } from './containers/SubscribeFormModal/reducer';

// eslint-disable-next-line no-unused-vars
export const createReducer = (state) => combineReducers({
    mapbox: mapboxReducer,
    map: mapReducer,
    subscribeForm: subscribeFormReducer
});
