import { createEpicMiddleware } from 'redux-observable';
import { applyMiddleware, compose, createStore } from 'redux';
import { switchMap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { createReducer } from './reducers';
import { rootEpic } from './epics';

const epicMiddleware = createEpicMiddleware();

// eslint-disable-next-line no-unused-vars
export default function configureStore(initialState = {}, history) {
    const middlewares = [epicMiddleware];
    const enhancers = [applyMiddleware(...middlewares)];
    /* eslint-disable no-underscore-dangle, indent */
    const composeEnhancers = process.env.NODE_ENV !== 'production'
    && typeof window === 'object'
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose;
    /* eslint-enable */
    const store = createStore(
        createReducer(),
        composeEnhancers(...enhancers)
    );
    // Extensions
    store.epic$ = epicMiddleware;
    store.injectedReducers = {}; // Reducer registry
    const epic$ = new BehaviorSubject(rootEpic);
    // Every time a new epic is given to epic$ it
    // will unsubscribe from the previous one then
    // call and subscribe to the new one because of
    // how switchMap works
    const hotReloadingEpic = (...args) => epic$.pipe(
        switchMap((epic) => epic(...args))
    );
    store.epic$.run(hotReloadingEpic);
    // Make reducers hot reloadable, see http://mxs.is/googmo
    /* istanbul ignore next */
    if (module.hot) {
        module.hot.accept('./reducers', () => {
            store.replaceReducer(createReducer(store.injectedReducers));
        });
        module.hot.accept('./epics', () => {
            // eslint-disable-next-line global-require
            epic$.next(require('./epics').rootEpic);
        });
    }
    return store;
}
