import styled from 'styled-components';
import { theme } from 'styled-tools';

const PrivacyInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: ${theme('colors.subscribeForm.privacyInfo.background')};
    align-items: center;
`;

export default PrivacyInfoContainer;
