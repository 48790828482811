import { prop } from 'ramda';

const websiteConfigs = {
    mojepierwszesoczewki: {
    },
    eyeoxy: {
        popoverLinkUrl: 'http://eyeoxy.bjdigitaldev.pl/'
    },
    iloox: {
        popoverLinkUrl: 'http://iloox.bjdigitaldev.pl/'
    }
};

export const websiteConfig = prop(process.env.REACT_APP_CONTEXT, websiteConfigs);
