import { createSelector } from 'reselect';

export const mapSelector = (state) => state.map;

export const getListOfPlaces = createSelector(
    mapSelector,
    (state) => state.get('listOfPlaces')
);

export const isListOfPlacesEmptySelector = createSelector(
    getListOfPlaces,
    (listOfPlaces) => listOfPlaces.size === 0
);

export const getListOfPlacesSortedByDistanceSelector = createSelector(
    getListOfPlaces,
    (listOfPlaces) => listOfPlaces.sortBy((place) => place.get('distance'))
);
