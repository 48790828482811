import { createGlobalStyle } from 'styled-components';
import { theme } from 'styled-tools';

const GlobalStyle = createGlobalStyle`
    html {
          font-family: ${theme('fonts.basic.fontFamily')};
          font-size: ${theme('fonts.basic.fontSize')};
    }

    body {
      margin: 0;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    
    input[type="text"], input[type="password"], select {
        background: #fff;
        border: 1px solid #6768869;
        color: #6768869;
        margin: 5px 0;
        padding: 5px 15px !important;
        max-width: 1100px;
        width: 100%;
        font-size: 14px;
    }
    
    .mapContainer {
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;    
    }
    
    .mapboxgl-popup-content h3 {
      margin-bottom: ${theme('dims.mapComponents.popupTitleMarginBottom')};
      margin-top: ${theme('dims.mapComponents.popupTitleMarginTop')};
    }

    .mapboxgl-popup-content {
        box-sizing: border-box;
        overflow: hidden;
        top: ${theme('dims.mapComponents.popoutContentTop')};
        left: ${theme('dims.mapComponents.popoutContentLeft')};
        background-color: ${theme('colors.mapComponents.popupBackgroundColor')};
        border-radius: ${theme('dims.mapComponents.popoutContentBorderRadius')};
        box-shadow: ${theme('shadows.mapComponents.popupContentBoxShadow')};
    }
    
    .mapboxgl-user-location-dot::before { 
        right: ${theme('dims.mapComponents.userLocationDotHeatWaveRight')};
    }
`;

export default GlobalStyle;
