import {
    switchMap, catchError, map, retryWhen, delayWhen
} from 'rxjs/operators';

import {
    from, of, timer
} from 'rxjs';

import { ofType, combineEpics } from 'redux-observable';

import { subscribeUserRequest, API_MAX_RETRIES, API_RETRY_DELAY_TIME } from '../../redux/api';
import { SUBSCRIBE_USER_REQUEST } from './constants';
import { subscribeErrorOccurred, subscribeSuccessAction } from './actions';

const subscribeUserEpic = (action$) => action$.pipe(
    ofType(SUBSCRIBE_USER_REQUEST),
    switchMap((subscribeData) => from(of(subscribeData)).pipe(
        switchMap(({
            name, email, phone, consents, placeId
        }) => from(subscribeUserRequest(name, email, phone, consents, placeId))
            .pipe((
                map(() => (subscribeSuccessAction()))
            ))),
        retryWhen((error$) => error$.pipe(
            map((error, i) => {
                if (i === API_MAX_RETRIES) {
                    throw error;
                }
                return error;
            }),
            delayWhen((_, i) => timer(API_RETRY_DELAY_TIME * i))
        )),
        catchError(((error) => of(subscribeErrorOccurred(error))))
    ))
);

const subscribeFormEpics = combineEpics(
    subscribeUserEpic
);

export default subscribeFormEpics;
