import styled from 'styled-components';
import { theme } from 'styled-tools';

const ModalTitle = styled.div`
    color: ${theme('colors.subscribeForm.modalTitle')};
    font-size: ${theme('fonts.subscribeForm.modalTitle.size')};
    margin-top: ${theme('dims.subscribeForm.modalTitle.marginTop')};
    margin-bottom: ${theme('dims.subscribeForm.modalTitle.marginBottom')};
    text-align: center;
`;

export default ModalTitle;
