import { combineEpics } from 'redux-observable';
import mapEpics from './redux/epics';
import mapboxEpics from './containers/Mapbox/epics';
import subscribeFormEpics from './containers/SubscribeFormModal/epics';

export const rootEpic = combineEpics(
    mapEpics,
    mapboxEpics,
    subscribeFormEpics
);
