import { colors, shadows } from './colors';
import { dims } from './dims';
import { fonts } from './fonts';
import { images } from './images';

export const theme = {
    colors,
    dims,
    fonts,
    images,
    shadows
};
