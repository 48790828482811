import styled from 'styled-components';
import { theme } from 'styled-tools';

const PopupSpan = styled.div`
    font-size: ${theme('fonts.mapPopup.span.size')};
    margin-top: ${theme('dims.mapPopup.span.marginHorizontal')};
    margin-bottom: ${theme('dims.mapPopup.span.marginHorizontal')};
`;

export default PopupSpan;
