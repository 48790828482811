import { reduce, max, min } from 'ramda';

import { fetchingError } from './actions';
import { CITY_DISTANCE, RETRY_QUERY_ADDITIONAL_RANGE } from './const';
import { haversineDistance } from '../utils';

export const handleFetchingError = (error) => {
    console.log(error);
    return fetchingError(error);
};

export const calculateNewMaxDistance = (iteration) => iteration * RETRY_QUERY_ADDITIONAL_RANGE + CITY_DISTANCE;

export const calculateFurthestPoints = (data) => {
    const {
        north, east, west, south, distance
    } = reduce(
        (acc, place) => ({
            north: max(acc.north, place.longitude),
            south: min(acc.south, place.longitude),
            east: max(acc.east, place.latitude),
            west: min(acc.west, place.latitude),
            distance: max(acc.distance, place.distance)
        }),
        {
            north: -180,
            south: 180,
            east: -180,
            west: 180,
            distance: 0
        },
        data
    );
    return {
        points: {
            north,
            south,
            east,
            west
        },
        distance
    };
};

export const calculateBounds = (bounds, latitude, longitude) => {
    const maxLongDist = max(bounds.north - longitude, longitude - bounds.south) * 2;
    const maxLatDist = max(bounds.east - latitude, latitude - bounds.west) * 2;

    return [
        [longitude + maxLongDist, latitude + maxLatDist],
        [longitude - maxLatDist, latitude - maxLatDist]
    ];
};

export const exportPlacesFromResponse = (response, latitude, longitude) => response.map((response) => {
    response.distance = haversineDistance([response.latitude, response.longitude], [latitude, longitude]);
    return response;
});
