import React from 'react';
import { identity } from 'ramda';
import PropTypes from 'prop-types';
import { StyledSearchButton } from './styledComponents/StyledSearchButton';
import { SearchIcon } from './styledComponents/SearchIcon';

export const SearchButton = ({ onClick }) => (
    <StyledSearchButton onClick={onClick}>
        <SearchIcon />
    </StyledSearchButton>
);

SearchButton.defaultProps = {
    onClick: identity
};

SearchButton.propTypes = {
    onClick: PropTypes.func
};
