import { fromJS } from 'immutable';

import {
    SET_PLACES,
    FETCHING_ERROR,
    NO_RESULTS, STATUS_STATES, FETCH_PLACES, FETCH_PLACES_BY_NAME
} from './const';
import { SET_MAP_SETTINGS } from '../containers/Mapbox/const';

const initialState = fromJS({
    listOfPlaces: [],
    fetchingError: {
        message: '',
        code: 0
    },
    status: STATUS_STATES.READY
});

export const mapReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PLACES:
            return state
                .set('listOfPlaces', action.placesList)
                .set('status', STATUS_STATES.READY);
        case FETCHING_ERROR:
            return state
                .set('fetchingError', fromJS({
                    message: action.message,
                    code: action.code
                }))
                .set('status', STATUS_STATES.ERROR);
        case NO_RESULTS:
            return state.set('status', STATUS_STATES.READY);
        case FETCH_PLACES:
            return state.set('status', STATUS_STATES.FETCHING);
        case FETCH_PLACES_BY_NAME:
            return state.set('status', STATUS_STATES.FETCHING);
        case SET_MAP_SETTINGS:
            return state.set('status', STATUS_STATES.READY);
        default:
            return state;
    }
};
