import { debounceTime, map } from 'rxjs/operators';
import { ofType, combineEpics } from 'redux-observable';

import { MOVE_MAP_REQUEST, MOVE_MAP_REQUEST_DEBOUNCE_TIME } from './const';
import { setMapSettings } from './actions';

const moveMapEpic = (action$) => action$.pipe(
    ofType(MOVE_MAP_REQUEST),
    debounceTime(MOVE_MAP_REQUEST_DEBOUNCE_TIME),
    map(({ latitude, longitude, zoom }) => setMapSettings({ latitude, longitude, zoom }))
);

const mapboxEpics = combineEpics(
    moveMapEpic
);

export default mapboxEpics;
