/* eslint-disable import/extensions */

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import configureStore from './store';
import 'core-js/features/promise';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import App from './containers/App/App';
import GlobalStyle from './global-style';
import { theme } from './themes';

const rootElement = document.getElementById('root');
const store = configureStore();

if (rootElement) {
    ReactDOM.render(
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <App />
            </ThemeProvider>
        </Provider>,
        rootElement
    );
}
