import styled from 'styled-components';
import { theme } from 'styled-tools';

const ExpandCheckboxes = styled.div`
    cursor: pointer;
    color: ${theme('colors.brandBlue')};
    font-size: ${theme('fonts.subscribeForm.checkboxLabel.size')};
    margin-left: ${theme('dims.subscribeForm.textInputLabel.marginLeft')};
`;

export default ExpandCheckboxes;
