import styled from 'styled-components';
import { theme } from 'styled-tools';

export const TextInputWrapper = styled.input`
    color: ${theme('colors.brandBlue')};
    font-weight: bold;
    margin-left: ${theme('dims.searchBar.TextInputWrapperLeftMargin')} !important;
    max-height: 50%;
    display: inline-block;
    text-align: center;
    cursor: text;
    border: none;
    outline: none;
    flex-grow: 1;
    
    ::placeholder {
        color: ${theme('colors.searchBar.inputPlaceholderFontColor')};
        opacity: 0.5; 
    }
    
    @media (max-width: ${theme('dims.mediaQueries.mobile')}) {
      font-size: ${theme('fonts.searchBar.searchBarSmallScreenInput')} !important;
      margin-left: ${theme('dims.searchBar.TextInputWrapperLeftMarginSmallScreens')} !important;
    }
    
    @media (min-width: ${theme('dims.mediaQueries.mobile')}) {
        font-size: ${theme('fonts.searchBar.searchBarInput')} !important;    
    }
    
    padding: unset !important;
    max-width: unset !important;
    
`;
